.home {
  & &__empty-content {
    display: flex;
    align-items: center;
    text-align: center;

    .empty-content-image,
    .empty-content-infos {
      width: 50%;
    }

    @media #{$screen-s}, #{$screen-m} {
      flex-direction: column;

      .empty-content-image,
      .empty-content-infos {
        width: 100%;
      }
    }
  }
}
