.modal-new-storage {
  .modal-new-storage-upload {
    display: flex;
    align-items: center;

    .file-upload-btn {
      margin: 0 .5rem;
    }
  }
}

.form-upload-panel {
  width: 100%;
  padding: 2rem;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-grey);
  position: relative;

  .form-upload-panel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form-upload-panel-img {
      display: block;
      height: 10rem;
      margin-bottom: 1rem;

      svg {
        height: 100%;
        max-width: 100%;
      }
    }

    .form-upload-panel-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      line-height: 2;
      font-size: $font-size-20;
      color: var(--color-midnight-light);
    }

    .form-upload-panel-name {
      word-break: break-all;
    }

    .form-upload-panel-button {
      margin-left: 1rem;
    }

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }
}

.storage-table__header-inputs {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xs);

  input[type=text] {
    min-width: 6rem;
  }

  &.highlighted input[type=text] {
    border: 2px solid var(--color-green);
  }
}
