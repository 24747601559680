.tag.tag-huge {
  width: 15rem !important;
  height: 12rem;
  flex-direction: column;
  transition: transform .1s ease-in-out;

  > i {
    font-size: $font-size-36;
    margin: 0 0 var(--spacing-sm)!important;
  }

  span {
    font-size: $font-size-20;
  }

  &:hover {
    transform: scale(1.1);
  }
}
