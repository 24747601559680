.activation-section {
  margin: 0 auto;
  max-width: 80rem;

  svg {
    max-height: 25rem;
    width: 100%;
  }
}

.activation-card {
  margin: var(--spacing-sm);

  .activation-card__title > span {
    color: var(--color-green);
  }
}
