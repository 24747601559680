.form-counting-filter-group {
  padding: var(--spacing-lg);
  background-color: var(--color-grey-light);
  border: 1px solid var(--color-grey);
  border-radius: var(--border-radius-md);

  .form-counting-filter-group-description {
    margin-bottom: var(--spacing-xs);
    color: var(--color-midnight-light);
  }

  .form-counting-filter-group-config {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
      flex-direction: column;

    }

    .form-counting-fields {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: var(--spacing-sm) calc(var(--spacing-md) * 2) var(--spacing-sm) 0;

      .form-counting-field-value {
        display: flex;
        align-items: center;

        button,
        .form-counting-field-loader {
          margin-left: var(--spacing-xs);
        }
      }
    }
  }
}

.form-counting-filter-separator {
  position: relative;
  height: 1px;
  width: 100%;
  margin: var(--spacing-sm) 0;
  color: var(--color-midnight-light);
  background-color: var(--color-grey);

  & > span {
    position: absolute;
    top: -0.75rem;
    left: 50%;
    transform: translateX(-50%);
    line-height: .5;
    padding: var(--spacing-xs) var(--spacing-sm);
    background-color: var(--color-grey-light);
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius-md);
  }
}

.form-counting-segment {
  width: 100%;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius-md);
  color: var(--color-midnight-light);
  background: var(--color-grey-light);
  padding: var(--spacing-xs) var(--spacing-sm);
  transition: all 0.3s;

  &.highlighted {
    background-color: var(--color-midnight-light);
    color: var(--color-grey);
  }

  i {
    font-size: $font-size-18;
  }

  .form-counting-segment-name {
    display: flex;
    align-items: center;

    > :not(:first-child) {
      margin-left: var(--spacing-xs);
    }
  }

  .form-counting-segment-actions {
    display: flex;
    align-items: center;
    margin-left: var(--spacing-xs);
    max-width: 10rem;

    &__tag {
      margin-right: var(--spacing-xs);
    }

    input[type=number] {
      width: 6rem;
      height: 1.75rem;
      font-size: $font-size-12;
    }

    &__button {
      margin-left: calc(var(--spacing-xs) / 2);
      height: 1.75rem;
    }
  }
}

.form-counting-filter {
  display: flex;
  align-items: center;
  padding: calc(var(--spacing-xs)/2) 0;
  color: var(--color-midnight-light);
  font-weight: $font-weight-regular;

  span {
    padding: calc(var(--spacing-xs) / 2) calc(var(--spacing-md) / 2);
    border-radius: var(--border-radius-md);
    margin: 0 var(--spacing-xs);
    font-family: $font-family-code;
    font-size: $font-size-12;
    background-color: var(--color-grey);
    color: var(--color-midnight);

    &:first-child {
      margin-left: 0;
    }
  }
}

.form-counting__field-content-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.form-counting-list {
  width: 100%;
  padding: var(--spacing-sm) 0 var(--spacing-sm) var(--spacing-sm);
  margin-bottom: var(--spacing-xs);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-grey);
  background-color: var(--color-white);
  position: relative;

  .form-counting-list-text {
    margin-bottom: var(--spacing-sm);
    font-weight: $font-weight-semi-bold;
    color: var(--color-midnight);
  }

  .form-counting-list-content {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
    justify-content: flex-start;
    padding-right: var(--spacing-sm);
    width: 100%;
    gap: var(--spacing-xs);
  }

  .form-counting-list-placeholder {
    padding: var(--spacing-xl) var(--spacing-lg);
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;
    font-size: $font-size-18;
    font-weight: $font-weight-regular;
    color: var(--color-grey-dark);
    text-align: center;
  }

  .form-counting-list-types {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    > span {
      margin: var(--spacing-sm);
    }
  }
}

.potential-budget-row {
  color: var(--color-midnight-light);
}
