.form-order-limit-group {
  margin: var(--spacing-lg);
}

.form-order-cost {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > span:first-child {
    font-size: $font-size-12;
    color: var(--color-grey-dark);
  }
}
